<template>
  <div class="container">
    <van-tabs
      :ellipsis="false"
      title-style="nav-title"
      title-active-color="#ffffff"
      title-inactive-color="#AAAAAA"
      background="#333333"
      v-model="active"
      sticky
      swipeable
      @click="onClick"
    >
      <van-tab
        v-for="(item,index) in impairment"
        :title="item.title+'('+item.image.children.length+')'"
        :key="index"
      >
        <div class="txt">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @touchMove="onTouchMoves"
            @touchStart="onTouchStarts"
            @touchEnd="onTouchEnds(item.image.children)"
          >
            <swiper-slide v-for="(ite,ind) in item.image.children" :key="ind">
              <div class="center">
                <van-image fit="cover" @click='onImageClick(item.image.children,ind)' :src="ite.image" />
                <ul class="problem">
                  <li>{{ite.label}}</li>
                </ul>
                <div class="money">减值{{formatCurrency(ite.money)}}</div>
                <div class="tips">{{ite.desc}}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { ImagePreview } from "vant";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: [],
  data() {
    return {
      swiperOption: {
        loop: false,
        // 显示分页
        pagination: {
          // el: ".swiper-pagination",
          // clickable: true //允许分页点击跳转
        },
      },
      current: 1,
      active: 0,
      startX: null,
      startXs: null,
      surrentStartX: null,
      surrentStartXs: null,
      nextPage: 1,
      impairment: "",
    };
  },
  computed: {
    swiper() {
      //    thiswx.nextTick(() => {
      return this.$refs.mySwiper.swiper;
      //   });
    },
  },
  watch: {},
  created() {},
  mounted() {
    console.log(this.$route.query.id);
    if (this.$route.query.id && this.$route.query.id !== 8) {
      this.active = this.$route.query.id - 0;
    }
    let list = [];
    this.$store.state.impairment;
    this.$store.state.impairment.forEach((item) => {
      if (item.image) {
        list.push(item);
      }
    });
    this.impairment = list;
    console.log(this.impairment);
    //
  },
  methods: {
    // 点击图片
    onImageClick(list, index) {
      console.log(list);
      let arr = []
      list.forEach(item=>{
        arr.push(item.image)
      })
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    onClick(name, title) {
      this.current = 1;
      this.nextPage = 1;
    },
    // 外部
    onTouchStarts(e) {
      e = e || event;
      if (e.touches.length == 1) {
        this.startXs = e.touches[0].clientX;
      }
    },
    onTouchMoves(e) {
      e = e || event;
      if (e.touches.length == 1) {
        this.surrentStartXs = e.touches[0].clientX;
      }
    },
    onTouchEnds(data) {
      this.$nextTick(() => {
        this.current = this.nextPage;
        if (this.surrentStartXs < this.startXs) {
          this.nextPage++;
          this.current++;
          if (this.nextPage > data.length) {
            this.active++;
            this.$refs.mySwiper[0].swiper.slideTo(1, 500, false);
            this.current = 1;
            this.nextPage = 1;
            if (this.active > this.impairment.length - 1) {
              this.active = this.impairment.length - 1;
              this.current = data.length;
              this.nextPage = data.length;
              this.$refs.mySwiper[0].swiper.slideTo(data.length, 500, false);
            }
          }
        }
        if (this.surrentStartXs > this.startXs) {
          this.nextPage--;
          this.current--;
          if (this.current == 0) {
            this.active--;
            if (this.active == -1) {
              this.current = 1;
              this.nextPage = 1;
            } else {
              this.$refs.mySwiper[0].swiper.slideTo(data.length, 500, false);
              this.current = data.length;
              this.nextPage = data.length;
            }
          }
        }
      });
    },
  },
};
</script>
<style lang='less'  scoped>
.container {
  height: 100vh;
  background: #333333;
}

.centers {
  width: 70vw;
  height: 20vh;
  background: #ffffff;
  margin: 20px auto;
  color: #000000;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.txt {
  width: 100vw;
  height: 100%;
}
.custom-indicator {
  color: #ffffff;
}
.center {
  width: 345px;
  margin: 0 auto;
  padding-top: 10px;
  color: #ffffff;
  padding-bottom: 40px;
}
/deep/ .center > .van-image {
  width: 345px;
  height: 259px;
  margin: 0 auto;
}
/deep/ .center > .van-image > img {
  border-radius: 5px;
}
.problem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 10px;
  font-size: 18px;
}
/deep/ .money {
  height: 20px;
  line-height: 20px;
  font-size: 15px !important;
}
.tips {
  font-size: 14px;
  color: #d0d0d0;
  margin-top: 15px;
  line-height: 18px;
}
/deep/ .van-tabs--line .van-tabs__wrap {
  height: 50px;
}

/deep/ .van-tabs__line {
  width: 20px !important;
  height: 10px !important;
  background-color: transparent !important;
  background: url("../../assets/report/white.png") center center;
  background-size: 100% 100%;
}
.swiper-container {
  background: #333333;
}
/deep/ .van-tab {
  font-size: 16px;
}
/deep/.van-tab--active > .van-tab__text {
  font-size: 19px;
}
</style>